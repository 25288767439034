import { cloneDeep, filter, find } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client/react/hooks";
import { settingGreen } from "../../../../common/colors";
import { DefaultCurrencyScenarios, PROJECT_COST_SUMMARY_HEADERS } from "./constants";
import {
  AppState,
  CostCurrencyScenario,
  CostHeaderMeasures,
  CurrencyScenario,
  EstimateCodeSelectionState,
  EstimateType,
  HierarchyItemEnum,
  ProjectCostColumnId,
  ProjectCostItem,
  ProjectCostsByCurrencyCode,
  ProjectCostsEditing,
  ProjectCostsResult,
  ProjectEstimateCodeCostItem,
} from "../../../../common/types";
import CostItemDetails from "./CostItemDetails";
import CostsDetails from "./CostsDetails";
import CurrencyScenarioSelection from "./CurrencyScenarioSelection";
import { GET_PROJECT_COSTS } from "./queries";
import { clearItemActuals, updateChildren } from "./utils";
import LoadingView from "../../../LoadingView";
import { connect } from "react-redux";
import ProjectCostEstimationStatus from "./ProjectCostEstimationStatus";
import FullscreenSpinner from "../../../FullscreenSpinner";

interface ProjectCostsProps {
  projectId: number;
  userCanEdit: boolean;
  hasCostsAccessOnly: boolean;
}

const mapStateToProps = (state: AppState) => ({
  description: state.projectHeaderState.item && state.projectHeaderState.item.description,
  projectVirtualType:
    state.projectHeaderState.item && state.projectHeaderState.item.type === HierarchyItemEnum.Project
      ? state.projectHeaderState.item.projectVirtualType
      : undefined,
});

const updateCurrencyScenarios = (costItemsByCurrencyCodes: ProjectCostsByCurrencyCode[]) => {
  return costItemsByCurrencyCodes.map(item => {
    return {
      id: item.currencyScenario,
      desc: item.currencyCode,
      estimatesCurrencyId: item.currencyScenario === CostCurrencyScenario.Custom ? item.currencyId : 1,
      actualsCurrencyId: item.currencyId,
      currencyCode: item.currencyCode,
      editable: item.currencyScenario === CostCurrencyScenario.LcProject,
    };
  });
};

const updateChildColumns = (items: ProjectCostItem[], column: ProjectCostColumnId, value: number | null) => {
  items.forEach(item => {
    item.values[column] = value;
    if (item.childItems) updateChildColumns(item.childItems, column, value);
  });
};

// TODO: Implement without mutating the existing data. Instead, return a new list of items.
//       Current implementation works only, if a deep clone of the original "filteredProjectItems"
//       is passed, as the values are mutated in-place.
const updateProjectCostItemColumn = (
  estimateSelectionState: EstimateCodeSelectionState,
  estimatesCostItemsByEstimateCode: ProjectEstimateCodeCostItem[] | undefined,
  filteredProjectItems: ProjectCostItem[],
  column: ProjectCostColumnId,
  baseItems: ProjectEstimateCodeCostItem | undefined,
  findDefaultPrevEstCode: (currentEstCode: string) => string | undefined
) => {
  if (column === "estimateChange") {
    if (estimatesCostItemsByEstimateCode) {
      const prevEstCodeSelection = estimateSelectionState["prevEstimate"];
      const curEstCodeSelection = estimateSelectionState["currentEstimate"];
      const prevEstCodeCostItem =
        prevEstCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === prevEstCodeSelection.id)
          : undefined;
      const curEstCodeCostItem =
        curEstCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === curEstCodeSelection.id)
          : undefined;
      if (prevEstCodeCostItem && curEstCodeCostItem) {
        filteredProjectItems.forEach(item => {
          const prevFoundItem = find(prevEstCodeCostItem.costItems, prevItem => prevItem.id === item.id);
          const curFoundItem = find(curEstCodeCostItem.costItems, curItem => curItem.id === item.id);
          if (prevFoundItem && curFoundItem) {
            item.values[column] =
              curFoundItem.values["currentEstimate"] !== undefined &&
              curFoundItem.values["currentEstimate"] !== null &&
              prevFoundItem.values["currentEstimate"] !== undefined &&
              prevFoundItem.values["currentEstimate"] !== null
                ? curFoundItem.values["currentEstimate"] - prevFoundItem.values["currentEstimate"]
                : null;
            if (item.childItems && curFoundItem.childItems && prevFoundItem.childItems) {
              updateChildren(item.childItems, curFoundItem.childItems, column, prevFoundItem.childItems);
            }
          }
        });
      }
    }
  } else if (column === "ETC") {
    const curEstCodeSelection = estimateSelectionState.currentEstimate;
    if (estimatesCostItemsByEstimateCode) {
      const curEstCodeCostItem =
        curEstCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === curEstCodeSelection.id)
          : undefined;
      if (baseItems && curEstCodeCostItem) {
        filteredProjectItems.forEach(item => {
          const baseFoundItem = find(baseItems.costItems, baseItem => baseItem.id === item.id);
          const curFoundItem = find(curEstCodeCostItem.costItems, curItem => curItem.id === item.id);
          if (baseFoundItem && curFoundItem) {
            const currentEstimate = curFoundItem.values["currentEstimate"] || 0;
            const committed = baseFoundItem.values["committed"] || 0;
            item.values[column] = currentEstimate - committed;
            if (item.childItems && curFoundItem.childItems && baseFoundItem.childItems) {
              updateChildren(item.childItems, curFoundItem.childItems, column, baseFoundItem.childItems);
            }
          }
        });
      }
    }
  } else if (column === "currentEstimate") {
    const curEstCodeSelection = estimateSelectionState["currentEstimate"];
    const defaultPrevEstCode = curEstCodeSelection ? findDefaultPrevEstCode(curEstCodeSelection.id) : undefined;
    if (estimatesCostItemsByEstimateCode) {
      const curEstCodeCostItem = find(
        estimatesCostItemsByEstimateCode,
        item => item.estimateCode === (curEstCodeSelection ? curEstCodeSelection.id : undefined)
      );
      const prevEstCodeCostItem = find(
        estimatesCostItemsByEstimateCode,
        item => item.estimateCode === defaultPrevEstCode
      );
      filteredProjectItems.forEach(item => {
        const curFoundItem = curEstCodeCostItem
          ? find(curEstCodeCostItem.costItems, curItem => curItem.id === item.id)
          : undefined;
        const prevFoundItem = prevEstCodeCostItem
          ? find(prevEstCodeCostItem.costItems, prevItem => prevItem.id === item.id)
          : undefined;

        if (curFoundItem) item.values.currentEstimate = curFoundItem.values.currentEstimate;

        if (item.childItems && curFoundItem && curFoundItem.childItems)
          updateChildren(item.childItems, curFoundItem.childItems, column, prevFoundItem && prevFoundItem.childItems);
      });
    }
  } else if (column == "periodicWipCosts") {
    const curEstCodeSelection = estimateSelectionState.currentEstimate;
    const prevEstCodeSelection = estimateSelectionState.prevEstimate;
    if (estimatesCostItemsByEstimateCode) {
      //console.log("Periodic wip costs: ", curEstCodeSelection);
      const curEstCodeCostItem =
        curEstCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === curEstCodeSelection.id)
          : undefined;
      const prevEstCodeCostItem =
        prevEstCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === prevEstCodeSelection.id)
          : undefined;
      if (prevEstCodeCostItem && curEstCodeCostItem) {
        filteredProjectItems.forEach(item => {
          const baseFoundItem = find(prevEstCodeCostItem.costItems, baseItem => baseItem.id === item.id);
          const curFoundItem = find(curEstCodeCostItem.costItems, curItem => curItem.id === item.id);
          if (baseFoundItem && curFoundItem) {
            const currentCumulativeWipCosts = curFoundItem.values.wipCostsCumulative || 0;
            const baseCumulativeWipCosts = baseFoundItem.values.wipCostsCumulative || 0;
            item.values.periodicWipCosts = currentCumulativeWipCosts - baseCumulativeWipCosts;
            if (item.childItems && curFoundItem.childItems && baseFoundItem.childItems) {
              updateChildren(item.childItems, curFoundItem.childItems, column, baseFoundItem.childItems);
            }
          }
        });
      }
    }
  } else {
    const estimateCodeSelection = estimateSelectionState[column === "estimatedHours" ? "currentEstimate" : column];
    if (estimatesCostItemsByEstimateCode) {
      const estimateCodeCostItem =
        estimateCodeSelection !== undefined
          ? find(estimatesCostItemsByEstimateCode, item => item.estimateCode === estimateCodeSelection.id)
          : undefined;
      if (estimateCodeCostItem) {
        estimateCodeCostItem.costItems.forEach(item => {
          const foundItem = find(filteredProjectItems, filteredItem => filteredItem.id === item.id);
          if (foundItem) {
            const updateColumn: ProjectCostColumnId = column === "prevEstimate" ? "currentEstimate" : column;
            foundItem.values[column] = item.values[updateColumn];
            if (foundItem.childItems && item.childItems) {
              updateChildren(foundItem.childItems, item.childItems, column);
            }
          }
        });
      } else {
        filteredProjectItems.forEach(project => {
          project.values[column] = null;
          if (project.childItems) updateChildColumns(project.childItems, column, null);
        });
      }
    }
  }
};

const filterCostItemsByCurrency = (
  costItemsByCurrencyCodes: ProjectCostsByCurrencyCode[],
  selectedCurrencyScenario: CurrencyScenario
): { actualItems: ProjectCostsByCurrencyCode | undefined; estimateItems: ProjectCostsByCurrencyCode | undefined } => {
  switch (selectedCurrencyScenario.id) {
    case CostCurrencyScenario.Custom: {
      const actualItems = find(
        costItemsByCurrencyCodes,
        item =>
          item.currencyScenario === CostCurrencyScenario.Custom &&
          item.currencyCode === selectedCurrencyScenario.currencyCode
      );
      return {
        actualItems,
        estimateItems: actualItems,
      };
    }
    default: {
      const actualItems = find(
        costItemsByCurrencyCodes,
        item =>
          item.currencyScenario !== CostCurrencyScenario.Custom &&
          item.currencyId === selectedCurrencyScenario.actualsCurrencyId
      );
      return {
        actualItems,
        estimateItems:
          selectedCurrencyScenario.actualsCurrencyId === selectedCurrencyScenario.estimatesCurrencyId
            ? actualItems
            : find(
                costItemsByCurrencyCodes,
                item =>
                  item.currencyScenario !== CostCurrencyScenario.Custom &&
                  item.currencyId === selectedCurrencyScenario.estimatesCurrencyId
              ),
      };
    }
  }
};

const filterCostItemsByEstimate = (
  estimateSelectionState: EstimateCodeSelectionState,
  costItemsByActualCurrency: ProjectCostsByCurrencyCode | undefined,
  costItemsByEstimateCurrency: ProjectCostsByCurrencyCode | undefined
) => {
  const filteredProjectItems: ProjectCostItem[] = [];
  const clearActuals = !costItemsByActualCurrency;
  const actualsCostItemsByEstimateCode = costItemsByActualCurrency && costItemsByActualCurrency.costItemsByEstimateCode;
  const estimatesCostItemsByEstimateCode =
    costItemsByEstimateCurrency && costItemsByEstimateCurrency.costItemsByEstimateCode;
  const baseItems =
    (actualsCostItemsByEstimateCode && actualsCostItemsByEstimateCode[0]) ||
    (estimatesCostItemsByEstimateCode && estimatesCostItemsByEstimateCode[0]);
  if (baseItems) {
    baseItems.costItems.forEach(item => {
      const newItem = cloneDeep(item);
      filteredProjectItems.push(newItem);
    });
    const estimateCodesListDesc = estimatesCostItemsByEstimateCode
      ? estimatesCostItemsByEstimateCode.map(item => parseInt(item.estimateCode)).sort((a, b) => b - a)
      : undefined;
    const findDefaultPrevEstCode = (estCode: string): string | undefined => {
      if (estimateCodesListDesc === undefined) return undefined;
      else {
        const currentIndex = estimateCodesListDesc.findIndex(code => code.toString() === estCode);
        return currentIndex > -1 && currentIndex + 1 < estimateCodesListDesc.length
          ? estimateCodesListDesc[currentIndex + 1].toString()
          : undefined;
      }
    };
    filter(PROJECT_COST_SUMMARY_HEADERS, header => header.hasEstimateCode)
      .map(header => header.id)
      .forEach(column => {
        updateProjectCostItemColumn(
          estimateSelectionState,
          estimatesCostItemsByEstimateCode,
          filteredProjectItems,
          column,
          baseItems,
          findDefaultPrevEstCode
        );
      });
    if (clearActuals) clearItemActuals(filteredProjectItems);
  }

  return filteredProjectItems;
};

const findAllLeafItems = (costItem: ProjectCostItem): ProjectCostItem[] => {
  if (costItem.childItems == undefined || costItem.childItems.length == 0) {
    return [costItem];
  } else {
    return costItem.childItems.flatMap(item => findAllLeafItems(item));
  }
};

function ProjectCosts(props: ProjectCostsProps & ReturnType<typeof mapStateToProps>): React.ReactElement {
  const { projectId, description: projectDescription, projectVirtualType, userCanEdit, hasCostsAccessOnly } = props;
  const [filteredItems, setFilteredItems] = useState<ProjectCostItem[]>([]);
  const [leafItems, setLeafItems] = useState<ProjectCostItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<ProjectCostItem | undefined>(undefined);
  const [detailsPopupOpen, setDetailsPopupOpen] = useState<boolean>(false);
  const [openProjectItems, setOpenProjectItems] = useState<string[]>([]);
  const [cacheUpdated, setCacheUpdated] = useState<number | undefined>(undefined);
  const [estimateSelectionState, setEstimateSelectionState] = useState<EstimateCodeSelectionState>({
    asSold: undefined,
    originalBudget: undefined,
    proposedBudgetChange: undefined,
    revisedBudget: undefined,
    prevEstimate: undefined,
    currentEstimate: undefined,
  });
  const [currencyScenarios, setCurrencyScenarios] = useState(DefaultCurrencyScenarios);
  const [selectedCurrencyScenario, setSelectedCurrencyScenario] = useState(DefaultCurrencyScenarios[0]);
  const [selectedHeaderMeasures, setSelectedHeaderMeasures] = useState<CostHeaderMeasures | undefined>(undefined);
  const [costsEditing, setCostsEditing] = useState<ProjectCostsEditing | undefined>();

  const queryVariables = {
    projectId,
  };

  const { loading, data, error, refetch } = useQuery<ProjectCostsResult>(GET_PROJECT_COSTS, {
    variables: queryVariables,
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (!data) return;

      setCostsEditing(data.projectCosts.editing);

      setCurrencyScenarios(updateCurrencyScenarios(data.projectCosts.costItemsByCurrencyCode));
      const asSoldEstimateCode = find(
        data.projectCosts.estimateTypes,
        item => item.estimateType === EstimateType.AsSold
      );
      const originalBudgetEstimateCode = find(
        data.projectCosts.estimateTypes,
        item => item.estimateType === EstimateType.OriginalBudget
      );
      const proposedBudgetChangeEstimateCode = find(
        data.projectCosts.estimateTypes,
        item => item.estimateType === EstimateType.ProposedBudgetChange
      );
      const revisedBudgetEstimateCode = find(
        data.projectCosts.estimateTypes,
        item => item.estimateType === EstimateType.RevisedBudget
      );
      const currentEstimateCode = find(
        data.projectCosts.estimateTypes,
        item => item.estimateType === EstimateType.Estimate
      );

      const currentEstimate = currentEstimateCode
        ? currentEstimateCode.estimateCodes.length
          ? currentEstimateCode.estimateCodes[0]
          : undefined
        : undefined;

      let prevEstimate;

      if (currentEstimate && currentEstimateCode && currentEstimateCode.estimateCodes.length) {
        const filteredCodes = filter(
          currentEstimateCode.estimateCodes,
          code => code.id.slice(0, code.id.length - 2) !== currentEstimate.id.slice(0, code.id.length - 2)
        );
        if (filteredCodes.length) {
          prevEstimate = filteredCodes[0];
        } else {
          prevEstimate = currentEstimateCode.estimateCodes[0];
        }
      }

      setEstimateSelectionState({
        asSold: asSoldEstimateCode
          ? asSoldEstimateCode.estimateCodes.length
            ? asSoldEstimateCode.estimateCodes[0]
            : undefined
          : undefined,
        originalBudget: originalBudgetEstimateCode
          ? originalBudgetEstimateCode.estimateCodes.length
            ? originalBudgetEstimateCode.estimateCodes[0]
            : undefined
          : undefined,
        proposedBudgetChange: proposedBudgetChangeEstimateCode
          ? proposedBudgetChangeEstimateCode.estimateCodes.length
            ? proposedBudgetChangeEstimateCode.estimateCodes[0]
            : undefined
          : undefined,
        revisedBudget: revisedBudgetEstimateCode
          ? revisedBudgetEstimateCode.estimateCodes.length
            ? revisedBudgetEstimateCode.estimateCodes[0]
            : undefined
          : undefined,
        prevEstimate,
        currentEstimate,
      });
    },
  });

  const estimateLeafItems = useMemo(() => {
    if (!data) return [];

    const estimateItemsByCurrency = filterCostItemsByCurrency(
      data.projectCosts.costItemsByCurrencyCode,
      DefaultCurrencyScenarios[0] // LC project scenario is editable
    ).estimateItems;
    if (!estimateItemsByCurrency) return [];

    const estimate = data.projectCosts.estimateTypes.find(item => item.estimateType == EstimateType.Estimate);
    if (!estimate || estimate.estimateCodes.length === 0) return [];

    const latestEstimateCodeId = (function () {
      const sorted = estimate.estimateCodes.map(code => code.id).sort((a, b) => a.localeCompare(b));
      return sorted[sorted.length - 1];
    })();
    const latestEstimate = estimateItemsByCurrency.costItemsByEstimateCode.find(
      item => item.estimateCode == latestEstimateCodeId
    );
    return ((latestEstimate && latestEstimate.costItems) || []).flatMap(item => findAllLeafItems(item));
  }, [data]);

  const onEstimateSelectionStateChange = useCallback(() => {
    if (data) {
      const itemsByCurrency = filterCostItemsByCurrency(
        data.projectCosts.costItemsByCurrencyCode,
        selectedCurrencyScenario
      );
      const filteredItems = filterCostItemsByEstimate(
        estimateSelectionState,
        itemsByCurrency.actualItems,
        itemsByCurrency.estimateItems
      );
      setFilteredItems(filteredItems);
      setLeafItems(filteredItems.length > 0 ? findAllLeafItems(filteredItems[0]) : []);
      !hasCostsAccessOnly &&
        itemsByCurrency.actualItems &&
        setSelectedHeaderMeasures(itemsByCurrency.actualItems.costHeaderMeasures);
    }
  }, [estimateSelectionState, data, selectedCurrencyScenario, hasCostsAccessOnly]);

  useEffect(() => {
    if (currencyScenarios.length > 0) {
      setSelectedCurrencyScenario(currencyScenarios[0]);
    }
  }, [currencyScenarios]);
  useEffect(onEstimateSelectionStateChange, [estimateSelectionState, selectedCurrencyScenario]);

  useEffect(() => {
    if (cacheUpdated) onEstimateSelectionStateChange();
  }, [cacheUpdated, onEstimateSelectionStateChange]);

  const itemDetails = selectedItem !== undefined && projectVirtualType !== undefined && (
    <CostItemDetails
      title={selectedItem.description}
      projectId={projectId}
      projectVirtualType={projectVirtualType}
      itemId={selectedItem.id}
      selectedCurrencyScenario={selectedCurrencyScenario}
      currencyRate={selectedItem.currencyRate || 1.0}
      popupOpen={detailsPopupOpen}
      closePopup={() => setDetailsPopupOpen(false)}
    />
  );

  // if all leaf activities are blocked, this project is finished and can't be edited anymore.
  const allBlocked =
    estimateLeafItems.length !== 0 && estimateLeafItems.every(item => item.tooltip && item.tooltip.isBlocked);
  // null and 0 is considered as the same value here
  // VALPOPAMS-394: previous estimate values can actually have decimals, but new estimate values are always rounded
  // to the nearest integer value. Thus, we might incorrectly detect a change in the estimate, when comparing to the
  // previous estimate, unless we round the values first
  const currentEstBlockedAndChanged = estimateLeafItems.filter(
    item =>
      item.tooltip &&
      item.tooltip.isBlocked &&
      item.values.lastEstimate !== undefined &&
      Math.round(item.values.currentEstimate || 0) != Math.round(item.values.lastEstimate || 0)
  );

  console.log("Blocked and changed:", currentEstBlockedAndChanged);

  return (
    <Container>
      {data && !error && costsEditing !== undefined && (
        <ContentContainer>
          <TabsRow>
            <CurrencyScenarioSelection
              currencyScenarios={currencyScenarios}
              selectedCurrencyScenario={selectedCurrencyScenario}
              currencyScenarioSelected={currencyScenario => setSelectedCurrencyScenario(currencyScenario)}
            />
            <ProjectCostEstimationStatus
              costEstimationStatus={data.projectCosts.costEstimationStatus}
              editing={costsEditing}
            />
          </TabsRow>
          <ScenarioContainer>
            <CurrencyDetails>
              {selectedCurrencyScenario.id}: {selectedCurrencyScenario.currencyCode}
            </CurrencyDetails>
            {projectVirtualType !== undefined && (
              <CostsDetails
                projectId={projectId}
                projectVirtualType={projectVirtualType}
                projectItems={filteredItems}
                onSelectedForDetails={item => {
                  setSelectedItem(item);
                }}
                selectedDetailsItem={selectedItem}
                projectDescription={projectDescription}
                onSave={() => refetch(queryVariables)}
                estimateCodesAndStatuses={data.projectCosts.estimateTypes}
                estimateCodesSelectionState={estimateSelectionState}
                setEstimateCodesSelectionState={state => setEstimateSelectionState(state)}
                refetchData={() => refetch()}
                openProjectItems={openProjectItems}
                setOpenProjectItems={setOpenProjectItems}
                onCacheUpdated={lastUpdated => setCacheUpdated(lastUpdated)}
                userCanEdit={userCanEdit && costsEditing.enabled}
                editing={costsEditing}
                allBlocked={allBlocked}
                currentEstBlockedAndChanged={currentEstBlockedAndChanged}
                selectedCurrencyScenario={selectedCurrencyScenario}
                headerMeasures={selectedHeaderMeasures}
                openDetailsPopup={() => setDetailsPopupOpen(true)}
              />
            )}
            {itemDetails}
          </ScenarioContainer>
        </ContentContainer>
      )}
      {data === undefined && loading && <LoadingView />}
      {data !== undefined && loading && <FullscreenSpinner text={"Reloading"} />}
      {error && <LoadingContainer>Error loading project costs data.</LoadingContainer>}
      {!loading && !error && !data && <LoadingContainer>No data available.</LoadingContainer>}
    </Container>
  );
}

export default connect(mapStateToProps)(ProjectCosts);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  margin: 0 5px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 2000px) {
    align-self: center;
    min-width: 1800px;
    max-width: 100%;
  }
  @media (max-width: 2000px) {
    min-width: 1500px;
    max-width: 100%;
  }
  @media (max-width: 1500px) {
    min-width: 1500px;
  }
`;

const TabsRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ScenarioContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${settingGreen};
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
`;

const CurrencyDetails = styled.div`
  padding: 0px 20px;
  font-size: 16px;
`;
