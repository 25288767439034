import { ApolloClient } from "@apollo/client";
import { GraphQLError } from "graphql";
import { gql } from "@apollo/client";
import { omit } from "lodash";
import {
  ActivityEstimateCommentTypeId,
  ActivityId,
  AddCostCommentResult,
  CommentId,
  CostActivityCommentsChange,
  CostActivityEstimateChange,
  CostComponentChange,
  CostComponentSaveResult,
  DeleteCostCommentResult,
  EstimateType,
  ISOLocalDateTime,
  PollReadyResult,
  ProjectId,
  SaveEstimateCodeStatusResult,
  StatusChangeData,
} from "../../../../common/types";

const ProjectCostItemValueFields = gql`
  fragment ProjectCostItemValueFields on ProjectCostValues {
    asSold
    originalBudget
    proposedBudgetChange
    revisedBudget
    calculatedEstimate
    lastEstimate
    currentEstimate
    committed
    actuals
    hardCommitments
    wipCosts
    wipCostsCumulative
    softCommitments
    estimatedHours
    actualHours
  }
`;

const ProjectCostItemGeneralFields = gql`
  fragment ProjectCostItemGeneralFields on ProjectCostItem {
    id
    projectRelatingKey1
    description
    currencyId
    currencyCode
    currencyRate
    estimateCodeId
    hasWarrantyOrContingencyComponents
    tooltip {
      isBlocked
      coldLinesExist
    }
  }
`;

export const GET_PROJECT_COSTS = gql`
  query ProjectCosts($projectId: ProjectId!) {
    projectCosts(projectId: $projectId) {
      costEstimationStatus
      estimateTypes {
        estimateType
        estimateCodes {
          id
          description
        }
        estimateValues {
          estimateCode
          estimateCodeStatus
          previousEstimateCode
          isPopProcessing
          isSentToLn
          isReconcilingToLn
          lnInterfaceMessage
          approvedBy
          interfaceStatus
        }
      }
      costItemsByCurrencyCode {
        currencyScenario
        currencyId
        currencyCode
        costItemsByEstimateCode {
          estimateCode
          currencyId
          currencyCode
          costItems {
            ...ProjectCostItemGeneralFields
            values {
              ...ProjectCostItemValueFields
            }
            childItems {
              ...ProjectCostItemGeneralFields
              values {
                ...ProjectCostItemValueFields
              }
              childItems {
                ...ProjectCostItemGeneralFields
                values {
                  ...ProjectCostItemValueFields
                }
                childItems {
                  ...ProjectCostItemGeneralFields
                  values {
                    ...ProjectCostItemValueFields
                  }
                  childItems {
                    ...ProjectCostItemGeneralFields
                    values {
                      ...ProjectCostItemValueFields
                    }
                    childItems {
                      ...ProjectCostItemGeneralFields
                      values {
                        ...ProjectCostItemValueFields
                      }
                      childItems {
                        ...ProjectCostItemGeneralFields
                        values {
                          ...ProjectCostItemValueFields
                        }
                        childItems {
                          ...ProjectCostItemGeneralFields
                          values {
                            ...ProjectCostItemValueFields
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        costHeaderMeasures {
          currencyId
          currencyCode
          currencyRate
          asSoldNetSales
          budgetedNetSales
          revisedNetSales
          previousEstimatedNetSales
          currentEstimatedNetSales
          backlog
        }
      }
      editing {
        enabled
        disabledReason
      }
    }
  }
  ${ProjectCostItemValueFields}
  ${ProjectCostItemGeneralFields}
`;

export const GET_ESTIMATE_CODES = gql`
  query EstimateCodesForProject($projectId: ProjectId!) {
    estimateCodesForProject(projectId: $projectId) {
      id
      description
    }
  }
`;

export const GET_PROJECT_COST_COMPONENTS = gql`
  query GetProjectCostComponents(
    $projectId: ProjectId!
    $activityIds: [ActivityId!]!
    $estimateCodeId: EstimateCodeId
    $previousEstimateCodeId: EstimateCodeId
    $revisedBudgetEstimateCodeId: EstimateCodeId
    $estimatesCurrencyId: CurrencyId!
    $actualsCurrencyId: CurrencyId!
    $customCurrencyCode: String
  ) {
    projectCostComponents(
      projectId: $projectId
      activityIds: $activityIds
      estimateCodeId: $estimateCodeId
      previousEstimateCodeId: $previousEstimateCodeId
      revisedBudgetEstimateCodeId: $revisedBudgetEstimateCodeId
      estimatesCurrencyId: $estimatesCurrencyId
      actualsCurrencyId: $actualsCurrencyId
      customCurrencyCode: $customCurrencyCode
    ) {
      id
      description
      values {
        ...ProjectCostItemValueFields
      }
      components {
        id
        activityId
        description
        values {
          ...ProjectCostItemValueFields
        }
      }
      isEditingBlocked
      currencyRate
    }
  }
  ${ProjectCostItemValueFields}
`;

export const GET_ESTIMATE_COMMENT_TYPES = gql`
  query GetEstimateCommentTypes {
    estimateCommentTypes
  }
`;

export const GET_PROJECT_COST_COMMENTS = gql`
  query GetProjectCostComments($projectId: ProjectId!, $activityIds: [ActivityId!]!, $estimateCodeId: EstimateCodeId!) {
    projectCostComments(projectId: $projectId, activityIds: $activityIds, estimateCodeId: $estimateCodeId) {
      activityId
      comments {
        commentId
        commentType
        commentText
        modifiedDate
        modifiedBy
      }
    }
  }
`;

export const GET_PROJECT_COST_ACTIVITY_DATA_POINTS = gql`
  query GetProjectCostActivityDataPoints(
    $projectId: ProjectId!
    $activityIds: [ActivityId!]!
    $currencyId: CurrencyId!
  ) {
    projectCostActivityDataPoints(projectId: $projectId, activityIds: $activityIds, currencyId: $currencyId) {
      activityId
      description
      actuals
      committed
    }
  }
`;

const SAVE_COST_COMPONENTS = gql`
  mutation SaveCostComponents($projectId: ProjectId!, $changes: [CostActivityEstimateChange!]!) {
    saveCostComponents(projectId: $projectId, changes: $changes) {
      success
      activityIdsForUpdatedEstimates
      applicationModifiedDateTime
      messages
    }
  }
`;

const POLL_FOR_COST_COMPONENTS_SAVE_READY = gql`
  query PollForCostComponentsSaveReady(
    $projectId: ProjectId!
    $activityIds: [ActivityId!]!
    $applicationModifiedDateTime: ISOLocalDateTime!
  ) {
    pollForCostComponentsSaveReady(
      projectId: $projectId
      activityIds: $activityIds
      applicationModifiedDateTime: $applicationModifiedDateTime
    ) {
      ready
    }
  }
`;

const queryForCostComponentSaveReady = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  activityIds: string[],
  applicationModifiedDateTime: string
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  try {
    const { data, errors } = await client.query<{ pollForCostComponentsSaveReady: PollReadyResult }>({
      query: POLL_FOR_COST_COMPONENTS_SAVE_READY,
      variables: { projectId, activityIds, applicationModifiedDateTime },
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    });

    return [data ? data.pollForCostComponentsSaveReady : undefined, errors];
  } catch (e) {
    console.error(e);
    return [undefined, undefined];
  }
};

const orUndefinedChange = (change: CostComponentChange) => {
  const round = (n: number): number => Math.round(n);
  return {
    costComponentId: change.costComponentId,
    revisedBudget: change.revisedBudget != null ? round(change.revisedBudget) : undefined,
    currentEstimate: change.currentEstimate != null ? round(change.currentEstimate) : undefined,
    estimatedHours: change.estimatedHours != null ? round(change.estimatedHours) : undefined,
    originalBudget: change.originalBudget != null ? round(change.originalBudget) : undefined,
    asSold: change.asSold != null ? round(change.asSold) : undefined,
  };
};

export const saveProjectCostComponents = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  estimateCodeId: string | undefined,
  revisedBudgetEstimateCodeId: string | undefined,
  asSoldEstimateCodeId: string | undefined,
  originalBudgetEstimateCodeId: string | undefined,
  currencyId: number,
  changes: CostActivityEstimateChange[],
  pollInterval = 1000
): Promise<[boolean, string[]]> => {
  const { data, errors } = await client
    .mutate<{ saveCostComponents: CostComponentSaveResult }>({
      mutation: SAVE_COST_COMPONENTS,
      variables: {
        projectId,
        changes: changes.map(change => {
          return {
            activityId: change.activityId,
            currencyId,
            estimateCodeId,
            revisedBudgetEstimateCodeId,
            asSoldEstimateCodeId,
            originalBudgetEstimateCodeId,
            costComponents: change.costComponents.map(change => {
              return omit(orUndefinedChange(change), ["initialItem"]);
            }),
          };
        }),
      },
    })
    .catch(e => {
      return e;
    });

  if (errors) {
    console.error(`Saving cost components failed with ${errors}`);
    return [false, ["Internal error during saving"]];
  } else if (!data || !data.saveCostComponents) {
    console.error(`Got invalid response when saving cost components`);
    return [false, ["Internal error during saving"]];
  } else if (!data.saveCostComponents.success || !data.saveCostComponents.applicationModifiedDateTime) {
    console.error(`Got invalid response when saving cost components`);
    return [false, data.saveCostComponents.messages];
  }

  const { applicationModifiedDateTime, messages } = data.saveCostComponents;

  const activityIds = data.saveCostComponents.activityIdsForUpdatedEstimates;

  return await new Promise(resolve => {
    const waitForReady = async () => {
      const [pollData, pollErrors] = await queryForCostComponentSaveReady(
        client,
        projectId,
        activityIds,
        applicationModifiedDateTime
      );

      if (pollErrors) {
        console.error(pollErrors);
        resolve([false, [...messages, "Error while polling save response"]]);
      } else if (pollData && pollData.ready) {
        resolve([true, messages]);
      } else {
        setTimeout(waitForReady, pollInterval);
      }
    };

    setTimeout(waitForReady, pollInterval);
  });
};

const SAVE_COST_COMMENTS = gql`
  mutation SaveCostComments($projectId: ProjectId!, $changes: CostActivityCommentsChange!) {
    saveCostComments(projectId: $projectId, changes: $changes) {
      applicationModifiedDateTime
    }
  }
`;

const POLL_FOR_COST_COMMENTS_SAVE_READY = gql`
  query PollForCostCommentsSaveReady(
    $projectId: ProjectId!
    $activityIds: [ActivityId!]!
    $applicationModifiedDateTime: ISOLocalDateTime!
  ) {
    pollForCostCommentsSaveReady(
      projectId: $projectId
      activityIds: $activityIds
      applicationModifiedDateTime: $applicationModifiedDateTime
    ) {
      ready
    }
  }
`;

const queryForCostCommentSaveReady = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  activityIds: string[],
  applicationModifiedDateTime: string
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .query<{ pollForCostCommentsSaveReady: PollReadyResult }>({
      query: POLL_FOR_COST_COMMENTS_SAVE_READY,
      variables: { projectId, activityIds, applicationModifiedDateTime },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  return [data ? data.pollForCostCommentsSaveReady : undefined, errors];
};

export const saveProjectCostComment = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: ProjectId,
  changes: CostActivityCommentsChange,
  pollInterval = 1000
): Promise<string | undefined> => {
  type CostCommentSaveResult = {
    applicationModifiedDateTime: ISOLocalDateTime;
  };
  const { data, errors } = await client
    .mutate<{ saveCostComments: CostCommentSaveResult }>({
      mutation: SAVE_COST_COMMENTS,
      variables: {
        projectId,
        changes,
      },
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });
  if (errors) {
    console.log(`Saving cost comments failed with ${errors}`);
    return undefined;
  } else if (!data || !data.saveCostComments || !data.saveCostComments.applicationModifiedDateTime) {
    console.log(`Got invalid response when saving cost comments`);
    return undefined;
  }

  const applicationModifiedDateTime = data.saveCostComments.applicationModifiedDateTime;
  const activityIds = changes.costComments.map(comment => comment.activityId);
  const distinctActivityIds = Array.from(new Set(activityIds));

  return await new Promise(resolve => {
    const waitForReady = async () => {
      const [pollData, pollErrors] = await queryForCostCommentSaveReady(
        client,
        projectId,
        distinctActivityIds,
        applicationModifiedDateTime
      );
      if (pollErrors) {
        console.error(pollErrors);
        resolve(undefined);
      } else if (pollData && pollData.ready) {
        resolve(applicationModifiedDateTime);
      } else {
        setTimeout(waitForReady, pollInterval);
      }
    };
    setTimeout(waitForReady, pollInterval);
  });
};

const ADD_COST_COMMENT = gql`
  mutation AddCostComment(
    $projectId: ProjectId!
    $activityId: ActivityId!
    $estimateCodeId: EstimateCodeId!
    $commentText: String!
    $commentType: ActivityEstimateCommentTypeId!
  ) {
    addCostComment(
      projectId: $projectId
      activityId: $activityId
      estimateCodeId: $estimateCodeId
      commentText: $commentText
      commentType: $commentType
    ) {
      comment {
        commentId
        commentType
        commentText
        modifiedDate
        modifiedBy
      }
      errors
    }
  }
`;

export const addCostComment = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  activityId: ActivityId,
  estimateCodeId: string,
  commentText: string,
  commentType: ActivityEstimateCommentTypeId
): Promise<AddCostCommentResult | undefined> => {
  const { data, errors } = await client
    .query<{ addCostComment: AddCostCommentResult }>({
      query: ADD_COST_COMMENT,
      variables: { projectId, activityId, estimateCodeId, commentText, commentType },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });
  if (errors) {
    console.log(`Adding new cost comment failed with ${errors}`);
  } else if (!data || !data.addCostComment || !data.addCostComment.comment) {
    console.log(`Got invalid response when adding new cost comment`);
  }
  if (data && data.addCostComment && data.addCostComment.errors) {
    console.log(`Response with ${data.addCostComment.errors}`);
  }
  return data ? data.addCostComment : undefined;
};

const DELETE_COST_COMMENT = gql`
  mutation DeleteCostComment(
    $projectId: ProjectId!
    $activityId: ActivityId!
    $estimateCodeId: EstimateCodeId!
    $commentId: CommentId!
  ) {
    deleteCostComment(
      projectId: $projectId
      activityId: $activityId
      estimateCodeId: $estimateCodeId
      commentId: $commentId
    ) {
      deleteDateTime
      errors
    }
  }
`;

export const deleteCostComment = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  activityId: ActivityId,
  estimateCodeId: string,
  commentId: CommentId
): Promise<DeleteCostCommentResult | undefined> => {
  const { data, errors } = await client
    .query<{ deleteCostComment: DeleteCostCommentResult }>({
      query: DELETE_COST_COMMENT,
      variables: { projectId, activityId, estimateCodeId, commentId },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });
  if (errors) {
    console.log(`Deleting a cost comment failed with ${errors}`);
  } else if (!data || !data.deleteCostComment || !data.deleteCostComment.deleteDateTime) {
    console.log(`Got invalid response when deleting a cost comment`);
  }
  if (data && data.deleteCostComment && data.deleteCostComment.errors) {
    console.log(`Response with ${data.deleteCostComment.errors}`);
  }
  return data ? data.deleteCostComment : undefined;
};

const SAVE_ESTIMATE_CODE_AND_STATUS = gql`
  mutation SaveEstimateCodeAndStatus($estimateStatusInput: EstimateStatusInput!) {
    saveEstimateCodeAndStatus(estimateStatusInput: $estimateStatusInput) {
      applicationModifiedDateTime
      error
    }
  }
`;

const POLL_ESTIMATE_CODE_STATUS_SAVE_READY = gql`
  query PollEstimateCodeStatusSaveReady(
    $estimateStatusInput: EstimateStatusInput!
    $activityIds: [ActivityId!]
    $applicationModifiedDateTime: ISOLocalDateTime!
  ) {
    pollEstimateCodeStatusSaveReady(
      estimateStatusInput: $estimateStatusInput
      activityIds: $activityIds
      applicationModifiedDateTime: $applicationModifiedDateTime
    ) {
      ready
    }
  }
`;

const queryEstimateCodeStatusSaveReady = async (
  client: ApolloClient<Record<string, unknown>>,
  changeData: StatusChangeData,
  applicationModifiedDateTime: string,
  activityIds: ActivityId[]
): Promise<[PollReadyResult | undefined, Readonly<GraphQLError[] | undefined>]> => {
  const { data, errors } = await client
    .query<{ pollEstimateCodeStatusSaveReady: PollReadyResult }>({
      query: POLL_ESTIMATE_CODE_STATUS_SAVE_READY,
      variables: {
        estimateStatusInput: {
          projectId: changeData.projectId,
          estimateType: changeData.estimateType,
          estimateCode: changeData.estimateCode,
          estimateCodeStatus: changeData.status,
        },
        applicationModifiedDateTime,
        activityIds,
      },
      fetchPolicy: "no-cache",
    })
    .then(res => {
      return res;
    })
    .catch(e => {
      console.error(e);
      return e;
    });

  return [data ? data.pollEstimateCodeStatusSaveReady : undefined, errors];
};

const pollEstimateCodeStatusSave = (
  client: ApolloClient<Record<string, unknown>>,
  changeData: StatusChangeData,
  applicationModifiedDateTime: string,
  activities: ActivityId[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>,
  onComplete: () => void
) => {
  const pollInterval = 1000;
  const waitForReady = async () => {
    const [pollData, pollErrors] = await queryEstimateCodeStatusSaveReady(
      client,
      changeData,
      applicationModifiedDateTime,
      activities
    );

    if (pollErrors) {
      console.error(pollErrors);
      setLoading(false);
      setError("Error polling estimate code status change.");
    } else if (pollData && pollData.ready) {
      onComplete();
    } else {
      setTimeout(waitForReady, pollInterval);
    }
  };
  setTimeout(waitForReady, pollInterval);
};

export const saveEstimateCodeAndStatus = async (
  client: ApolloClient<Record<string, unknown>>,
  changeData: StatusChangeData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: () => void,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  setLoading(true);
  setError(undefined);
  await client
    .mutate({
      mutation: SAVE_ESTIMATE_CODE_AND_STATUS,
      variables: {
        estimateStatusInput: {
          projectId: changeData.projectId,
          estimateType: changeData.estimateType,
          estimateCode: changeData.estimateCode,
          estimateCodeStatus: changeData.status,
        },
      },
    })
    .then(res => {
      if (res.data) {
        const data = res.data.saveEstimateCodeAndStatus as SaveEstimateCodeStatusResult;
        const applicationModifiedDateTime = data.applicationModifiedDateTime;
        if (applicationModifiedDateTime && !data.error) {
          pollEstimateCodeStatusSave(
            client,
            changeData,
            applicationModifiedDateTime,
            data.activitiesChanged,
            setLoading,
            setError,
            onComplete
          );
        } else {
          setLoading(false);
          setError(data.error ? data.error : "Error saving status change.");
        }
      } else {
        setLoading(false);
        setError("Error saving status change.");
      }
    })
    .catch(() => {
      setLoading(false);
      setError("Error saving status change.");
    });
};

const SAVE_PROPOSED_BUDGET_ESTIMATE_CODE_AND_STATUS = gql`
  mutation SaveProposedBudgetEstimateCodeAndStatus(
    $estimateStatusInput: EstimateStatusInput!
    $draftEstimates: [DraftEstimate!]!
  ) {
    saveProposedBudgetEstimateCodeAndStatus(
      estimateStatusInput: $estimateStatusInput
      draftEstimates: $draftEstimates
    ) {
      applicationModifiedDateTime
      activitiesChanged
      error
    }
  }
`;

export const saveProposedBudgetEstimateCodeAndStatus = async (
  client: ApolloClient<Record<string, unknown>>,
  changeData: StatusChangeData,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: () => void,
  setError: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  setLoading(true);
  setError(undefined);
  await client
    .mutate({
      mutation: SAVE_PROPOSED_BUDGET_ESTIMATE_CODE_AND_STATUS,
      variables: {
        estimateStatusInput: {
          projectId: changeData.projectId,
          estimateType: changeData.estimateType,
          estimateCode: changeData.estimateCode,
          estimateCodeStatus: changeData.status,
        },
        draftEstimates: changeData.relatedStatuses,
      },
    })
    .then(res => {
      if (res.data) {
        const data = res.data.saveProposedBudgetEstimateCodeAndStatus as SaveEstimateCodeStatusResult;
        const applicationModifiedDateTime = data.applicationModifiedDateTime;
        if (applicationModifiedDateTime && !data.error) {
          pollEstimateCodeStatusSave(
            client,
            changeData,
            applicationModifiedDateTime,
            data.activitiesChanged,
            setLoading,
            setError,
            onComplete
          );
        } else {
          setLoading(false);
          setError(data.error ? data.error : "Error saving status change.");
        }
      } else {
        setLoading(false);
        setError("Error saving status change.");
      }
    })
    .catch(() => {
      setLoading(false);
      setError("Error saving status change.");
    });
};

const COPY_ESTIMATE_CODE_COLUMN = gql`
  mutation CopyEstimateCodeColumn(
    $projectId: ProjectId!
    $estimateType: EstimateType!
    $sourceEstimateCodeId: EstimateCodeId!
    $targetEstimateCodeId: EstimateCodeId!
  ) {
    copyEstimateCodeColumn(
      projectId: $projectId
      estimateType: $estimateType
      sourceEstimateCodeId: $sourceEstimateCodeId
      targetEstimateCodeId: $targetEstimateCodeId
    )
  }
`;

export const copyEstimateCodeColumn = async (
  client: ApolloClient<Record<string, unknown>>,
  projectId: number,
  estimateType: EstimateType,
  sourceEstimateCodeId: string,
  targetEstimateCodeId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  onComplete: () => void,
  setError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  setError(false);
  await client
    .mutate({
      mutation: COPY_ESTIMATE_CODE_COLUMN,
      variables: {
        projectId,
        estimateType,
        sourceEstimateCodeId,
        targetEstimateCodeId,
      },
    })
    .then(res => {
      if (res.data) onComplete();
      else {
        setLoading(false);
        setError(true);
      }
    })
    .catch(() => {
      setLoading(false);
      setError(true);
    });
};
