import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ApolloClient, gql } from "@apollo/client";
import { connect } from "react-redux";
import { Action } from "redux";
import styled from "styled-components";
import { useApolloClient, useMutation, useQuery } from "@apollo/client/react/hooks";
import { faEdit, faExclamationCircle, faLock, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addCustomRecognitionColumnSet, setRecognitionColumnSet } from "../../../../actions/columnSetActions";
import {
  changeRecognitionsWaitingForReady,
  copyEstRecNsToCogs,
  endRecognitionsEditing,
  startRecognitionsEditing,
  startRecognitionsMassFreeze,
} from "../../../../actions/recognitionsEditActions";
import {
  cancelRed,
  commentBlue,
  defaultGrey,
  disabledGrey,
  projectDetailsYellow,
  settingGreen,
  valmetGreyBorder,
} from "../../../../common/colors";
import { ALL_DEFAULT_RECOGNITION_COLUMN_SETS, AVAILABLE_RECOGNITION_COLUMNS } from "../../../../common/columns";
import { RECOGNITION_EDIT_COLUMNS, RecPlanEstRecEditModes } from "./recognitionsEditingSetting";
import { Period, periodEquals } from "../../../../common/period";
import { AppState, Currency, HierarchyItemEnum, ProjectCurrency, ProjectId } from "../../../../common/types";
import { ColumnSet } from "../../../../common/columnsTypes";
import {
  EditableRecognitionRow,
  RecognitionEditType,
  RecognitionPlanChange,
  RecognitionPlanSaveResult,
  RecognitionsEditStateChange,
  RecognitionWaitingState,
} from "./types";
import FullscreenSpinner from "../../../FullscreenSpinner";
import ColumnSetsDropdownSection from "../../Projects/ColumnConfiguration/ColumnSetsDropdownSection";
import ColumnSettingsModal from "../../Projects/ColumnConfiguration/ColumnSettingsModal";
import SaveCopyConfirmDialog from "./SaveCopyConfirmDialog";
import { GET_PROJECT_CURRENCIES, pollForRecPlanSaveReady, SAVE_RECOGNITIONS } from "./queries";
import { PROJECT_RECOGNITION_EDITING_DISABLED } from "../queries";
import { useWindowActive } from "../../../../hooks/useWindowActive";
import { IconButton, ToggleSwitch } from "../../../../common/components";
import FullscreenWaitingDialog from "../../../FullscreenWaitingDialog/FullscreenWaitingDialog";
import { useWindowClose } from "../../../../hooks/useWindowClose";
import { initLogger } from "../../../../logging";
import EstRecEditModeDropdown from "./EstRecEditModeDropdown";
import { ThunkDispatch } from "redux-thunk";

const logger = initLogger(__filename);

const mapStateToProps = (state: AppState) => {
  return {
    state: state.recognitionEditState,
    selectedColumnSet: state.columnSetState.recognitionColumnSet,
    selectedCurrency: state.settingsState.selectedCurrency,
    legalEntityCurrency:
      state.projectHeaderState.item && state.projectHeaderState.item.type === HierarchyItemEnum.Project
        ? state.projectHeaderState.item.legalEntityCurrency
        : undefined,
    //projectId: state.recognitionEditState.projectId,
    editingRecognitions: state.recognitionEditState.editing,
    waitingForReady: state.recognitionEditState.waitingForReady,
    recognitionsChanges: state.recognitionEditState.changes,
    saveChanges: state.recognitionEditState.saveChanges,
    editedRecognitions: state.recognitionEditState.recognitions,
    recEditType: state.recognitionEditState.options.editProjectType,
    isAdmin: state.authState.user ? state.authState.user.isAdmin : false,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setColumnSet: (columnSet: ColumnSet) => dispatch(setRecognitionColumnSet(columnSet)),
    addCustomRecognitionColumnSet: (columnSet: ColumnSet) => dispatch(addCustomRecognitionColumnSet(columnSet)),
    onStartRecognitionsEditing: () => dispatch(startRecognitionsEditing),
    onEndRecognitionsEditing: () => dispatch(endRecognitionsEditing),
    startRecognitionsMassFreezing: () => dispatch(startRecognitionsMassFreeze(null, null)),
    copyEstRecNsToCogs: (periods: Period[]) =>
      dispatch((dispatch, getState) => {
        const isEstimateEditable =
          getState().recognitionEditState.options.editProjectType !== RecognitionEditType.LnServiceOrder;
        const userPreference = getState().userPreferencesState.estRecEditMode;
        const selectedEstRecEditMode = isEstimateEditable ? userPreference : RecPlanEstRecEditModes.WipCosts;
        dispatch(copyEstRecNsToCogs(periods, selectedEstRecEditMode));
      }),
    changeRecognitionsWaitingForReady: (
      projectId: ProjectId,
      state: RecognitionWaitingState,
      modifiedDateTime: string | null
    ) =>
      dispatch(
        changeRecognitionsWaitingForReady(projectId, { state: state, applicationModifiedDateTime: modifiedDateTime })
      ),
    clearRecognitionsWaitingForReady: (projectId: ProjectId) =>
      dispatch(changeRecognitionsWaitingForReady(projectId, null)),
  };
};

type ColumnSetSelectionProps = {
  itemType: HierarchyItemEnum;
  projectId: ProjectId | null;
  userCanEdit: boolean;
  hidePreOBL: boolean;
  setHidePreOBL: (value: boolean) => void;
  projectRecAsFlat: boolean;
  setProjectRecAsFlat: (value: boolean) => void;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const SAVE_VIEW = gql`
  mutation SaveViewProject($view: ViewInput!) {
    saveView(view: $view) {
      id
      name
      columns
      isGlobal
    }
  }
`;

const DELETE_VIEW = gql`
  mutation DeleteViewProject($id: ViewId!) {
    deleteView(id: $id)
  }
`;

const onSave = async (
  columnSet: ColumnSet,
  client: ApolloClient<Record<string, unknown>>,
  setLastMutated: React.Dispatch<React.SetStateAction<number | undefined>>,
  setColumnSet: (columnSet: ColumnSet) => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  isGlobal: boolean
) => {
  setLoading(true);
  try {
    const { data } = await client.mutate({
      mutation: SAVE_VIEW,
      variables: {
        view: {
          id: columnSet.id.length ? columnSet.id : undefined,
          name: columnSet.description,
          columns: columnSet.columns.map(column => column.id),
          isGlobal,
        },
      },
    });
    if (data) {
      setLastMutated(Date.now());
      setColumnSet(columnSet);
    }
  } catch (error) {
    console.log(error);
  }

  setLoading(false);
};

const onDeleteView = async (
  id: string,
  client: ApolloClient<Record<string, unknown>>,
  setLastMutated: React.Dispatch<React.SetStateAction<number | undefined>>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  const { data, errors } = await client
    .mutate({
      mutation: DELETE_VIEW,
      variables: { id },
    })
    .catch(e => {
      return e;
    });
  if (data && !errors) {
    setLastMutated(Date.now());
  }
  setLoading(false);
};

interface ProjectRecognitionEditingDisabledResult {
  message: string | null;
  disabled: boolean;
  recalculateMessage: string | null;
  recalculateDisabled: boolean;
}

function ColumnSetSelection(props: ColumnSetSelectionProps): React.ReactElement {
  const {
    itemType,
    selectedColumnSet,
    setColumnSet,
    editingRecognitions,
    recognitionsChanges,
    saveChanges,
    editedRecognitions,
    projectId,
    selectedCurrency,
    legalEntityCurrency,
    onStartRecognitionsEditing,
    onEndRecognitionsEditing,
    startRecognitionsMassFreezing,
    copyEstRecNsToCogs,
    userCanEdit,
    isAdmin,
    recEditType,
    hidePreOBL,
    setHidePreOBL,
    projectRecAsFlat,
    setProjectRecAsFlat,
    waitingForReady,
    clearRecognitionsWaitingForReady,
    changeRecognitionsWaitingForReady,
  } = props;
  const client = useApolloClient() as ApolloClient<Record<string, unknown>>;
  const [lastMutated, setLastMutated] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [editableColumnSet, setEditableColumnSet] = useState<ColumnSet | undefined>(undefined);
  const [copyConfirmData, setCopyConfirmData] = useState<EditableRecognitionRow[] | undefined>(undefined);

  const waitSate = waitingForReady ? waitingForReady.state : null;
  const waitModifiedDateTime = waitingForReady ? waitingForReady.applicationModifiedDateTime : null;
  const getWaitText = (waitSate: RecognitionWaitingState | null, waitModifiedDateTime: string | null) => {
    if (waitSate) {
      if (!waitModifiedDateTime)
        switch (waitSate) {
          case RecognitionWaitingState.Saving:
            return "Start save...";
          case RecognitionWaitingState.Recalculating:
            return "Start recalculation...";
        }
      else
        switch (waitSate) {
          case RecognitionWaitingState.Saving:
            return "Saving";
          case RecognitionWaitingState.Recalculating:
            return "Recalculating";
        }
    } else return null;
  };
  const [waitText, setWaitText] = useState<string | null>(getWaitText(waitSate, waitModifiedDateTime));
  const [waitDialogOpen, setWaitDialogOpen] = useState<boolean>(waitSate != null && waitModifiedDateTime == null);

  useEffect(() => {
    setWaitText(getWaitText(waitSate, waitModifiedDateTime));
    if (!waitDialogOpen) setWaitDialogOpen(waitSate != null && waitModifiedDateTime == null);
  }, [waitSate, waitModifiedDateTime]);

  const handleWindowClose = useCallback(() => {
    if (waitDialogOpen && waitModifiedDateTime != null) setWaitDialogOpen(false);
  }, [waitDialogOpen, waitModifiedDateTime, setWaitDialogOpen]);

  useWindowClose(handleWindowClose);

  const shouldSkipPolling = projectId === null || projectId === undefined || !userCanEdit;
  const recEditingDisabledResult = useQuery<{
    projectRecognitionEditingDisabled: ProjectRecognitionEditingDisabledResult | null;
  }>(PROJECT_RECOGNITION_EDITING_DISABLED, {
    variables: { projectId },
    skip: shouldSkipPolling,
    fetchPolicy: "no-cache",
    pollInterval: 5000,
  });

  useWindowActive(active => {
    if (active && !shouldSkipPolling) {
      recEditingDisabledResult.startPolling(5000);
    } else {
      recEditingDisabledResult.stopPolling();
    }
  });

  const { editing, recalculate } = useMemo(() => {
    if (recEditingDisabledResult.data && recEditingDisabledResult.data.projectRecognitionEditingDisabled) {
      logger.debug("Rec editing disabled result changed:", recEditingDisabledResult.data);
      return {
        editing: {
          disabled: recEditingDisabledResult.data.projectRecognitionEditingDisabled.disabled,
          message: recEditingDisabledResult.data.projectRecognitionEditingDisabled.message,
        },
        recalculate: {
          disabled: recEditingDisabledResult.data.projectRecognitionEditingDisabled.recalculateDisabled,
          message: recEditingDisabledResult.data.projectRecognitionEditingDisabled.recalculateMessage,
        },
      };
    } else {
      return {
        editing: { disabled: true, message: undefined },
        recalculate: { disabled: true, message: undefined },
      };
    }
  }, [recEditingDisabledResult.data]);

  const editingEnabled =
    userCanEdit &&
    selectedCurrency &&
    selectedCurrency.id === 1 &&
    !editing.disabled &&
    itemType === HierarchyItemEnum.Project &&
    recEditType !== RecognitionEditType.NonEditable &&
    recEditType !== RecognitionEditType.NonEditableAutomaticAdjustmentOnNS &&
    recEditType !== RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS;

  const editingDisabledMessage =
    recEditType === RecognitionEditType.NonEditableAutomaticAdjustmentOnIntNS ||
    recEditType === RecognitionEditType.NonEditableAutomaticAdjustmentOnNS
      ? "Editing disabled. Only the rate between two top-level currencies is editable."
      : undefined;

  useEffect(() => {
    const parameters = {
      userCanEdit,
      selectedCurrency,
      itemType,
      recEditType,
      editing,
    };
    logger.debug("Rec editing enabled:", editingEnabled, parameters);
  }, [userCanEdit, selectedCurrency, itemType, recEditType, editing]);

  const rowsWithDifferentNsAndCogs = (): EditableRecognitionRow[] => {
    const changes = Array.from(recognitionsChanges.values()).filter(
      v => !RECOGNITION_EDIT_COLUMNS.isCalculatedColumn(v.columnId, v.field)
    );

    if (changes.length === 0) return [];

    const editedEstRecPeriods = changes
      .filter(change => {
        switch (change.columnId) {
          case "est_edit":
            return change.field === "ns" || change.field === "cost";
          case "est_rec_edit":
            return change.field === "ns" || change.field === "cogs";
        }
        return false;
      })
      .map(change => change.period);

    return editedRecognitions.filter(row => {
      if (editedEstRecPeriods.find(p => periodEquals(p, row.period))) {
        const estRecPercent = row.getCell("est_rec_percent_edit");
        const value = estRecPercent.value;
        return value.cogsPercent !== value.nsPercent;
      }
      return false;
    });
  };

  const {
    pollForReady,
    ready: recPlanSaveReady,
    loading: pollingForRecPlanSaveReady,
    error: pollingForRecPlanSaveError,
  } = pollForRecPlanSaveReady();

  // If users closed the tab/window during polling, when they open this project again, they will be stuck at waiting page.
  // So whenever this project's waitingForReady.applicationModifiedDateTime is valid, start polling again.
  useEffect(() => {
    if (projectId && waitModifiedDateTime !== null && !pollingForRecPlanSaveReady) {
      pollForReady(projectId, waitModifiedDateTime);
    }
  }, [projectId, waitModifiedDateTime, pollingForRecPlanSaveReady]);
  useEffect(() => {
    if (!pollingForRecPlanSaveReady && recPlanSaveReady !== undefined && projectId) {
      clearRecognitionsWaitingForReady(projectId);
      onEndRecognitionsEditing();
      if (!recPlanSaveReady) setWaitDialogOpen(true);
    }
  }, [recPlanSaveReady, pollingForRecPlanSaveReady]);

  const [saveRecognitions] = useMutation<{
    saveRecognitionPlan: RecognitionPlanSaveResult;
  }>(SAVE_RECOGNITIONS);

  const saveRecPlan = useCallback(
    (projectId: number, currencyId: number, changes: RecognitionsEditStateChange[], state: RecognitionWaitingState) => {
      changeRecognitionsWaitingForReady(projectId, state, null);
      saveRecognitions({
        variables: {
          projectId,
          changes: changes.map(
            c =>
              ({
                period: `${c.period.year}-${c.period.month}`,
                column: c.columnId,
                field: c.field,
                value: c.value,
                freezing: c.freezingComment ? { comment: c.freezingComment } : undefined,
              } as RecognitionPlanChange)
          ),
        },
      }).then(res => {
        console.log("### Saving recognitions ###");
        console.log(changes);
        if (!res || !res.data) {
          console.error("No response.");
        } else if (!res.data.saveRecognitionPlan.applicationModifiedDateTime) {
          console.error("Missing applicationModifiedDateTime");
        } else if (res.data && res.data.saveRecognitionPlan.error) {
          console.error(res.data.saveRecognitionPlan.error);
        } else {
          changeRecognitionsWaitingForReady(projectId, state, res.data.saveRecognitionPlan.applicationModifiedDateTime);
          pollForReady(projectId, res.data.saveRecognitionPlan.applicationModifiedDateTime);
        }
      });
    },
    [saveRecognitions, changeRecognitionsWaitingForReady, pollForReady]
  );

  const saveRecPlanWithChanges = useCallback(
    (projectId: ProjectId, currency: Currency, data: RecognitionsEditStateChange[]) => {
      const changes = data.filter(v => !RECOGNITION_EDIT_COLUMNS.isCalculatedColumn(v.columnId, v.field));
      if (changes.length > 0) {
        saveRecPlan(projectId, currency.id, changes, RecognitionWaitingState.Saving);
      }
    },
    [saveRecPlan]
  );

  const saveRecPlanWithoutChanges = useCallback(
    (projectId: ProjectId, currency: Currency) => {
      saveRecPlan(projectId, currency.id, [], RecognitionWaitingState.Recalculating);
    },
    [saveRecPlan]
  );

  useEffect(() => {
    if (saveChanges && projectId && selectedCurrency) {
      saveRecPlanWithChanges(projectId, selectedCurrency, saveChanges);
    }
  }, [saveChanges, projectId, selectedCurrency, saveRecPlanWithChanges]);

  const copyAndSave = (projectId: ProjectId, currency: Currency, periods: Period[]) => {
    copyEstRecNsToCogs(periods);
    setCopyConfirmData(undefined);
  };

  const save = (projectId: ProjectId, currency: Currency, data: RecognitionsEditStateChange[]) => {
    setCopyConfirmData(undefined);
    saveRecPlanWithChanges(projectId, currency, data);
  };

  const onRecSave = (projectId: ProjectId, currency: Currency) => {
    const rows = rowsWithDifferentNsAndCogs();
    if (rows.length > 0) {
      setCopyConfirmData(rows);
    } else {
      saveRecPlanWithChanges(projectId, currency, Array.from(recognitionsChanges.values()));
    }
  };

  const onRecalculate = (projectId: ProjectId, currency: Currency) => {
    saveRecPlanWithoutChanges(projectId, currency);
  };

  // For LN service orders the estimate cost, ns or est rec ns% or cogs% are not editable, so it does
  // not make sense to show the edit mode selection.
  const isEstimateEditable = recEditType !== RecognitionEditType.LnServiceOrder;

  type CurrencyResult = { projectDetails: { projectCurrency: ProjectCurrency } };
  const { data: currenciesData } = useQuery<{ projectDetails: CurrencyResult }>(GET_PROJECT_CURRENCIES, {
    variables: {
      projectId,
    },
  });

  const projectCurrency = currenciesData?.projectDetails.projectDetails.projectCurrency;
  const currencyCodeText = useMemo(() => {
    if (projectCurrency && selectedCurrency) {
      if (selectedCurrency.code.includes("LC")) {
        return legalEntityCurrency || "-";
      } else if (selectedCurrency.code.includes("ER")) {
        return projectCurrency.externalReportingCurrency || "-";
      } else if (selectedCurrency.code.includes("IR")) {
        return projectCurrency.internalReportingCurrency || "-";
      } else {
        return "EUR";
      }
    } else {
      return "-";
    }
  }, [projectCurrency, selectedCurrency]);

  const onRecCancel = () => onEndRecognitionsEditing();
  return (
    <RecColumnSetContainer>
      {waitText != null ? (
        <SavingText>
          {waitText}
          {waitSate && <FontAwesomeIcon icon={faSpinner} size="1x" color={disabledGrey} spin={true} />}
        </SavingText>
      ) : editingRecognitions && projectId && selectedCurrency ? (
        <>
          <SubContainer>
            <ToggleSwitch
              checked={hidePreOBL}
              onChange={value => setHidePreOBL(value)}
              text={"Hide periods before OBL period"}
              color={settingGreen}
              fontSize={"12px"}
            />
            {isEstimateEditable && <EstRecEditModeDropdown />}
            <CurrencyDescription>
              {selectedCurrency.description}: <span>{currencyCodeText}</span>
            </CurrencyDescription>
          </SubContainer>
          <SubContainer>
            <IconButton onClick={() => startRecognitionsMassFreezing()} color={settingGreen} hasText={true}>
              Mass freeze
              <FontAwesomeIcon icon={faLock} size="1x" color={commentBlue} />
            </IconButton>
            <IconButton onClick={() => onRecalculate(projectId, selectedCurrency)} color={settingGreen} hasText={true}>
              Recalculate
            </IconButton>
            <Spacer />
            <IconButton onClick={() => onRecSave(projectId, selectedCurrency)} color={settingGreen} hasText={true}>
              Save
              <FontAwesomeIcon icon={faSave} size="1x" color={settingGreen} />
            </IconButton>
            <IconButton onClick={() => onRecCancel()} color={cancelRed} hasText={true}>
              Cancel
            </IconButton>
          </SubContainer>
        </>
      ) : (
        <>
          <SubContainer>
            <ToggleSwitch
              checked={hidePreOBL}
              onChange={value => setHidePreOBL(value)}
              text={"Hide periods before OBL period"}
              color={settingGreen}
              fontSize={"12px"}
            />
            <ToggleSwitch
              checked={!projectRecAsFlat}
              onChange={() => setProjectRecAsFlat(!projectRecAsFlat)}
              text={"Group by year"}
              color={settingGreen}
              fontSize={"12px"}
            />
            {selectedCurrency && itemType === HierarchyItemEnum.Project && (
              <CurrencyDescription>
                {selectedCurrency.description}: <span>{currencyCodeText}</span>
              </CurrencyDescription>
            )}
          </SubContainer>
          <SubContainer>
            {editingEnabled && (
              <IconButton onClick={() => onStartRecognitionsEditing()} color={settingGreen} hasText={true}>
                Edit recognitions
                <FontAwesomeIcon icon={faEdit} size="1x" color={settingGreen} />
              </IconButton>
            )}
            {(editing.message || recalculate.message) && (
              <RecMessage>
                <Icon>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Icon>
                {!recalculate.message ? editing.message : recalculate.message}
              </RecMessage>
            )}
            {editingDisabledMessage && (
              <RecMessage>
                <Icon>
                  <FontAwesomeIcon icon={faExclamationCircle} />
                </Icon>
                {editingDisabledMessage}
              </RecMessage>
            )}
            {!recalculate.disabled && projectId && selectedCurrency && (
              <IconButton
                onClick={() => onRecalculate(projectId, selectedCurrency)}
                color={settingGreen}
                hasText={true}
              >
                Recalculate
              </IconButton>
            )}
            <ColumnSetsDropdownSection
              selectedColumnSet={selectedColumnSet}
              onColumnSetSelection={columnSet => setColumnSet(columnSet)}
              defaultColumnSets={ALL_DEFAULT_RECOGNITION_COLUMN_SETS}
              lastMutated={lastMutated}
              onDelete={id => onDeleteView(id, client, setLastMutated, setLoading)}
              onEdit={columnSet => setEditableColumnSet(columnSet)}
            />
            <ColumnSettingsModal
              onSave={(columnSet, isGlobal) =>
                onSave(columnSet, client, setLastMutated, setColumnSet, setLoading, isGlobal)
              }
              availableColumns={AVAILABLE_RECOGNITION_COLUMNS}
              resetEditableColumnSet={() => setEditableColumnSet(undefined)}
              editableColumnSet={editableColumnSet}
              isAdmin={isAdmin}
            />
          </SubContainer>
        </>
      )}
      {waitText && waitDialogOpen ? (
        <FullscreenWaitingDialog
          text={waitText}
          enableClose={!!waitModifiedDateTime}
          onClose={() => {
            setWaitDialogOpen(false);
          }}
        />
      ) : waitText && !waitDialogOpen ? (
        <FullscreenSpinner text={waitText} />
      ) : loading ? (
        <FullscreenSpinner text={undefined} />
      ) : pollingForRecPlanSaveError && waitDialogOpen ? (
        <FullscreenWaitingDialog
          text={pollingForRecPlanSaveError}
          enableClose={true}
          onClose={() => {
            setWaitDialogOpen(false);
          }}
          color={cancelRed}
        />
      ) : undefined}
      {projectId && selectedCurrency && copyConfirmData && (
        <SaveCopyConfirmDialog
          data={copyConfirmData}
          onCopyAndSave={() => {
            const periods = copyConfirmData.map(x => x.period);
            copyAndSave(projectId, selectedCurrency, periods);
          }}
          onSave={() => save(projectId, selectedCurrency, Array.from(recognitionsChanges.values()))}
          onCancel={() => setCopyConfirmData(undefined)}
        />
      )}
    </RecColumnSetContainer>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ColumnSetSelection);

const RecColumnSetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: white;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: space-between;
  @media (min-width: 1600px) {
    padding-right: 250px;
    padding-left: 250px;
  }
  @media (max-width: 1600px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 20px;
  flex-wrap: wrap;
  background: white;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: flex-start;
`;

const RecMessage = styled.div`
  line-height: 1.5;
  row-gap: 10px;
  padding: 6px;
  font-size: 12px;
  color: ${defaultGrey}
  background-color: ${projectDetailsYellow}
  border: 1px solid ${valmetGreyBorder}
  max-width: 300px;
  text-align: justify;
`;

const Spacer = styled.div`
  width: 60px;
`;

const Icon = styled.div`
  margin-right: 6px;
  display: inline;
`;

const SavingText = styled.div`
  font-size: 12px;
  svg {
    margin-left: 5px;
  }
`;

const CurrencyDescription = styled.div`
  color: ${settingGreen};
  font-size: 12px;
  span {
    font-weight: bold;
  }
`;
